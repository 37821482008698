// services
import {
  canSearchWithinRecord,
  createQueryModel
} from "@/services/query/queryService";

// mixins
import { dataTableSearchableDocumentsMixin } from "@/mixins/shared/documents/dataTableSearchableDocumentsMixin";
import {
  getFileContents,
  saveFile
} from "@/services/dom/fileSystemAccessService";
import { extensionName } from "@/model/record/fileModel";
import { iconCategories } from "@/design/icon/iconConst";

export const dataTableSearchableCategoryDocumentsMixin = {
  mixins: [dataTableSearchableDocumentsMixin],
  computed: {
    /**
     * can Search within a record
     * Overwrites searchableMixin.canSearchWithinRecord
     * @return {boolean}
     */
    canSearchWithinRecord() {
      return canSearchWithinRecord(this.record?.recordTypeId ?? -1);
    },

    /**
     * Get table Icon name
     * Overwrite dataTableMixin.tableIcon()
     * @return {string}
     */
    tableIcon() {
      return iconCategories;
    },

    /**
     * selected Category
     * Overwrite dataTableSearchableDocumentsMixin.selectedCategory()
     * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
     */
    selectedCategory() {
      return (this.category?.id ?? -1) >= 0
        ? this.category
        : this.searchCategory;
    },

    /**
     * selected Category id
     * @return {number|number} returns selected category id
     */
    selectedCategoryId() {
      return this.selectedCategory?.id ?? -1;
    },

    /**
     * Get selected category Name
     * @return {string} returns selected category name
     */
    selectedCategoryName() {
      return this.selectedCategory?.name ?? "";
    },

    /**
     * show Search Categories
     * @return {boolean}
     */
    showSearchCategories() {
      return (this.category?.id ?? -1) < 0;
    },

    /**
     * filter Dialog Title
     * Overwrite dataTableSearchableDocumentsMixin.filterDialogTitle
     * @return {string}
     */
    filterDialogTitle() {
      return this.selectedCategoryId >= 0
        ? `Search ${this.selectedCategoryName}`
        : `Search`;
    },

    /**
     * Data Table Title
     * Overwrites dataTableDocumentsMixin.dataTableTitle
     * @return {string} Formatted Data Table Title
     */
    dataTableTitle() {
      return this.hasFilter ? `Search ${this.selectedCategoryName}:` : "";
    }
  },
  methods: {
    /**
     * Overwrite dataTableMixin.setItems()
     * @returns {Promise<void>}
     */
    async setItems() {
      try {
        this.clearError();

        this.isLoading = true;
        this.filterFieldsCount = 0;

        await this.clearDocuments();
        this.resetSearchableRecordProperties();

        // Load Searchable Record Property's fields
        try {
          let props = this.dataTableRecordPropsConfiguration();
          const propsCount = this.countSearchableFilterProps(props);

          // console.log(`setItems() props:`, props);
          // console.log(`setItems() propsCount:`, propsCount);

          if (propsCount === 0) {
            props = this.defaultSearchableRecordProps(this.defaultHeaders());
          }
          this.loadSearchRecordProps(props);
        } catch (e) {
          console.error(e);
        }

        // Set Search Category
        if ((this.category?.id ?? -1) < 0) {
          const categoryId = this.fieldRecordCategory.value || -1;
          await this.setSearchCategory(categoryId);
        }

        // Load search-able Record's Fields
        try {
          const fields = this.dataTableFieldsConfiguration() || [];
          this.filterFieldsCount = this.countSearchableFilterFields(fields);

          this.loadSearchRecordFields(fields);
        } catch (e) {
          console.error(e);
        }

        // Insert Includable Fields to dataTable headers
        try {
          this.listHeaders = this.headers.filter(el => !!el.visible);
          this.insertIncludableRecordFields(this.listHeaders);
        } catch (e) {
          console.error(e);
        }

        if (!this.hasFilter && !((this.selectedCategory?.id ?? -1) > -1)) {
          return;
        }

        //Add name to searchToolbar (outside Filter) if conditions meet
        //this.populateSearchField();

        // Build Search Payload
        const payload = this.buildSearchPayload(-1, this.modelPage, true);
        console.log(`setItems() payload:`, payload);

        if (this.enableUniversalSearch && this.hasUniversalSearchCriteria) {
          this.buildUniversalSearchPayload(payload);
        } else {
          this.fieldUniversalSearch = this.createUniversalSearchField();
        }

        //Only Get Search Count first time, not on every page changed
        if (!this.totalCount) {
          const queryModel = createQueryModel(payload);
          this.totalCount = await this.getTotalSearchCount(queryModel);
        }

        // const hasSearchCriteria = this.hasSearchCriteria(payload);
        // console.log(`setItems() hasSearchCriteria:`, hasSearchCriteria);
        //if (this.hasFilter) {
        await this.setFoundDocuments(payload);
        //}
      } catch (e) {
        const error = e.toString();
        const pos = error.indexOf("Search conditions not provided");
        if (pos < 0) {
          this.handleError(error);
        }
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Overwrite Apply Search Filter
     * @param {Boolean} closeFilter
     * @return {Promise<void>}
     */
    async applySearchFilter(closeFilter = true) {
      try {
        this.filterError = "";
        closeFilter
          ? (this.isSearching = true)
          : (this.isApplyingSearch = true);
        this.isLoading = true;
        this.modelPage = 1; // set the default page to one on every new search

        const payload = this.buildSearchPayload(-1, this.modelPage, true);
        console.log(
          `${this.$options.name}.applySearchFilter() payload:`,
          payload
        );

        if (this.enableUniversalSearch && this.hasUniversalSearchCriteria) {
          this.buildUniversalSearchPayload(payload);
        } else {
          this.fieldUniversalSearch = this.createUniversalSearchField();
          //Add name to searchToolbar (outside Filter) if conditions meet
          //this.populateSearchField();
        }

        let props = this.searchableRecordProperties;
        // console.log(`${this.$options.name}.applySearchFilter() props:`, props);

        const propsCount = this.countSearchableFilterProps(props);
        console.log(
          `${this.$options.name}.applySearchFilter() propsCount:`,
          propsCount
        );

        if (propsCount === 0) {
          props = this.defaultSearchableRecordProps(this.defaultHeaders());
          this.loadSearchRecordProps(props);
        }

        const fields = this.searchableRecordFields;

        this.headers = this.defaultHeaders();
        this.setRecordPropertyHeadersVisibility(this.headers);

        this.listHeaders = this.headers.filter(el => !!el.visible);

        this.insertIncludableRecordFields(this.listHeaders);

        if (!this.hasFilter && !((this.selectedCategory?.id ?? -1) > -1)) {
          await this.clearDocuments();
          this.totalCount = 0; //for pagination reset
          this.menuFilterVisible = !closeFilter;
          this.storeSearchableFields([]); // clear local store for fields
          this.storeSearchableRecordProps([]); // clear local store for RecordProps
          return;
        }

        // Get Total search count to determine pagination Length
        const queryModel = createQueryModel(payload);
        this.totalCount = await this.getTotalSearchCount(queryModel);

        // const hasSearchCriteria = this.hasSearchCriteria(payload);
        // console.log(
        //   `applySearchFilter() hasSearchCriteria:`,
        //   hasSearchCriteria
        // );

        await this.setFoundDocuments(payload);

        // Store Searchable Fields
        this.storeSearchableFields(fields);
        this.filterFieldsCount = this.countSearchableFilterFields(fields);

        // Store Searchable Record Props
        this.storeSearchableRecordProps(props);
        //this.countSearchableFilterProps(props);

        this.isLoading = true;
        this.menuFilterVisible = !closeFilter;
      } catch (e) {
        this.filterError = e.toString();
      } finally {
        this.isSearching = false;
        this.isApplyingSearch = false;
        this.isLoading = false;
      }
    },

    /**
     * Overwrite save User Search Template
     * @return {Promise<void>}
     */
    async saveUserSearchTemplate() {
      try {
        this.filterError = "";

        const name = "template";
        const extension = extensionName.text;

        const payload = this.buildSearchPayload();
        console.log(`saveUserSearchTemplate() payload:`, payload);
        // Add GlobalSearch field to the payload object
        payload.GlobalSearch = this.fieldUniversalSearch;

        const serializedObject = JSON.stringify(payload);
        const blob = new Blob([serializedObject], { type: "text/plain" });

        await saveFile(blob, name, extension);
      } catch (e) {
        this.filterError = e.toString();
      }
    },

    /**
     * overwrite get User Search Template()
     * @return {Promise<void>}
     */
    async getUserSearchTemplate() {
      try {
        this.filterError = "";
        const model = await getFileContents(".txt");
        this.populateUserSearchTemplateData(model);
      } catch (e) {
        this.filterError = e.toString();
      }
    },

    /**
     * Set Total Result count for provided Query
     * @param {{CategoryId: number, TypeId: number, Owner: string, CreatedDateTo: string, StateId: number, Creator: string, Name: string, Extension: string, Pages: number, Fields: {any}[], Version: number, CreatedDateFrom: string, ModifiedDateTo: string, ModifiedDateFrom: string}} queryModel
     * @return {Promise<void>}
     */
    async getTotalSearchCount(queryModel) {
      try {
        const result = await this.getTotalCount(queryModel);
        return result?.data;
      } catch (e) {
        this.filterError = e.toString();
      }
    },

    /**
     * overwrite reset Search Filter
     * @param {{Boolean}} applySearch
     */
    async resetSearchFilter(applySearch = false) {
      try {
        this.filterError = "";

        this.performFilterReset();

        if (applySearch) {
          if ((this.selectedCategory?.id ?? -1) > -1)
            // Apply Search After Reset Done
            await this.applySearchFilter();
          else {
            await this.clearDocuments(); //clear all the displayed documents
            this.storeSearchableFields([]); // clear local store for fields
            this.storeSearchableRecordProps([]); // clear local store for RecordProps
            //this.menuFilterVisible = false; //close filter
            this.totalCount = 0; //for pagination reset
          }
        }
      } catch (e) {
        this.filterError = e.toString();
      }
    }
  }
};
