<template>
  <!--  <v-expansion-panels focusable multiple v-model="panelDocuments">-->
  <!--    <v-expansion-panel>-->
  <!--      <v-expansion-panel-header>-->
  <!--        <template v-slot:default="{ open }">-->
  <!--          <details-banner :avatar-text="nameInitials">-->
  <!--            <template v-slot:avatar>-->
  <!--              <v-avatar>-->
  <!--                <v-icon :color="getIconColorClass(open)" large>{{-->
  <!--                  iconFiles-->
  <!--                }}</v-icon>-->
  <!--              </v-avatar>-->
  <!--            </template>-->
  <!--            {{ tableTitle }}-->
  <!--          </details-banner>-->
  <!--        </template>-->
  <!--      </v-expansion-panel-header>-->
  <!--      <v-expansion-panel-content>-->
  <base-table-layout
    :error="currentError"
    @alertVisibilityChanged="onAlertVisibilityChanged"
  >
    <!-- toolbar -->
    <template v-slot:toolbar>
      <!--      :icon-name="tableIcon"-->
      <table-toolbar :icon-name="tableIcon">
        {{ tableTitle }}
        <!--                    <template v-slot:notifications>-->
        <!--                      <notification-menuFilterVisible-->
        <!--                        :notifications="notifications"-->
        <!--                        @selectedNotification="onSelectedNotification"-->
        <!--                      >-->
        <!--                      </notification-menuFilterVisible>-->
        <!--                    </template>-->
      </table-toolbar>
    </template>

    <!-- DataTable search -->
    <!--    <template v-slot:search v-if="visibleSearch">-->
    <!--      <search-text-field-->
    <!--        v-model="search"-->
    <!--        v-if="visibleSearch"-->
    <!--      ></search-text-field>-->
    <!--    </template>-->

    <!-- data Table -->
    <template v-slot:dataTable>
      <v-data-table
        v-model="selectedItems"
        :show-expand="showExpand"
        :show-select="true"
        :single-select="singleSelect"
        :item-key="itemKey"
        :headers="listHeaders"
        :items="items"
        :items-per-page="itemsPerPage"
        :multi-sort="multiSort"
        :search="searchValue"
        :loading="isLoading"
        :loading-text="loadingText"
        :item-class="rowBackgroundClassName"
        @click:row="showItemDetails"
        @item-expanded="onItemExpanded"
        :no-data-text="getEmptyTableDataText"
        :custom-sort="customSort"
      >
        <template v-slot:footer.prepend>
          <!-- Report Button for selected records -->
          <base-tooltip-button
            v-if="visibleReportDetailAction"
            @click="openReportDialog"
          >
            {{ commandDetailReport }}
            <template v-slot:toolTip>
              {{ commandDetailReportTooltip }}
            </template>
          </base-tooltip-button>
        </template>

        <template v-slot:top>
          <!-- Data table Toolbar-->
          <layout-data-table-toolbar>
            <!-- Data Table Toolbar Title-->
            <template #title v-if="visibleDataTableTitle">
              {{ dataTableTitle }}
            </template>

            <!-- Data Table Toolbar command buttons -->
            <template v-slot:commands>
              <!-- Search/Filter Dialog -->
              <search-filter-popover-menu
                v-model="menuFilterVisible"
                :filter-badge-message="filterBadgeMessage"
                :filter-badge-color-class="filterBadgeColorClass"
                :visible-badge="visibleFilterBadge"
              >
                <template #default>
                  <layout-dialog :loading="isSearching">
                    <!-- Filter Dialog title -->
                    <template #title>
                      {{ filterDialogTitle }}
                    </template>

                    <!-- Filter Dialog icon -->
                    <template #iconName>
                      {{ iconFilter }}
                    </template>

                    <!-- Close Search Dialog -->
                    <template #iconCrossName>
                      <base-tooltip-button @click="onFilterDialogCancel">
                        <template v-slot:icon-name>
                          {{ iconClose }}
                        </template>
                        <template v-slot:toolTip>
                          {{ commandCloseSearchDialogTooltip }}
                        </template>
                      </base-tooltip-button>
                    </template>

                    <!-- Filter Dialog context -->
                    <template #default>
                      <v-card-text>
                        <v-form v-bind="$attrs" v-on="$listeners">
                          <v-container fluid>
                            <!-- Error display -->
                            <base-alert
                              v-model="showFilterError"
                              :dismissible="dismissibleFilterError"
                              >{{ filterError }}</base-alert
                            >

                            <!-- Global Search  -->
                            <v-row dense>
                              <v-col>
                                <search-form-field
                                  :disabled-universal-search="
                                    !enableUniversalSearch
                                  "
                                  :disabled-universal-search-text="
                                    getDisabledSearchReason
                                  "
                                  :field="fieldUniversalSearch"
                                />
                              </v-col>
                            </v-row>

                            <!-- More  -->
                            <v-expansion-panels accordion multiple focusable>
                              <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <!-- Record Properties Header text -->
                                  <span
                                    :class="
                                      panelHeaderClass(
                                        advancedCriteriaList?.length > 0
                                      )
                                    "
                                    >{{ filterHeaderMore }}</span
                                  >
                                  <!-- Record Properties Sub Header text -->
                                  <core-chip-group
                                    :items="advancedCriteriaList"
                                    :show-arrows="true"
                                    :chip-close="true"
                                    @onSearchChipRemoved="
                                      event => onSearchChipRemoved(event, false)
                                    "
                                  ></core-chip-group>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <!-- Record Name -->
                                  <v-row class="mt-2">
                                    <v-col>
                                      <search-form-field
                                        :field="fieldRecordName"
                                        :disabled-include="true"
                                      />
                                    </v-col>
                                  </v-row>

                                  <!-- OCR Text -->
                                  <v-row>
                                    <v-col>
                                      <search-form-field
                                        :field="fieldFullText"
                                      />
                                    </v-col>
                                  </v-row>

                                  <!-- Record Category Form Fields -->
                                  <v-expansion-panel>
                                    <v-expansion-panel-header>
                                      <!-- Category Header text -->

                                      <span>
                                        <span
                                          :class="
                                            panelHeaderClass(
                                              hasAdvancedSearchableRecordCategoryFields
                                            )
                                          "
                                          class="mr-2"
                                          >{{
                                            filterHeaderCategoryFields
                                          }}</span
                                        >
                                      </span>

                                      <!-- Category Sub Header text -->
                                      <core-chip-group
                                        v-show="
                                          hasAdvancedSearchableRecordCategoryFields
                                        "
                                        :items="
                                          filterAdvancedSearchableRecordCategoryFields
                                        "
                                        :column="true"
                                        :chip-small="true"
                                        :chip-close="true"
                                        @onSearchChipRemoved="
                                          event =>
                                            onSearchChipRemoved(event, false)
                                        "
                                      >
                                        <template #default="{ item: field }">
                                          {{ categoryCriteriaToString(field) }}
                                        </template>
                                      </core-chip-group>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <!-- Select Document Category -->
                                      <v-row dense v-if="showSearchCategories">
                                        <v-col>
                                          <search-select-form-field
                                            :prepend-icon="iconCategories"
                                            :items="userCategories"
                                            :field="fieldRecordCategory"
                                            :visible-include="false"
                                            color="primary"
                                            item-text="name"
                                            item-value="id"
                                            label="Category"
                                            clearable
                                            dense
                                          >
                                          </search-select-form-field>
                                        </v-col>
                                      </v-row>
                                      <v-row dense v-else>
                                        <v-col>
                                          {{ selectedCategoryName }}
                                        </v-col>
                                      </v-row>

                                      <!-- Document Category's Searchable Fields -->
                                      <v-row
                                        dense
                                        v-for="(field,
                                        index) in categorySearchableFields"
                                        :key="index"
                                      >
                                        <v-col
                                          v-if="isFieldRangeSearchable(field)"
                                        >
                                          <search-form-field-range
                                            :field-from="field"
                                            :field-to="
                                              getRangeToField(
                                                categorySearchableFields,
                                                field
                                              )
                                            "
                                            v-show="!field.rangeTo"
                                          />
                                        </v-col>
                                        <v-col v-else>
                                          <search-form-field :field="field" />
                                        </v-col>
                                      </v-row>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>

                                  <!-- Record Properties -->
                                  <v-expansion-panel>
                                    <v-expansion-panel-header>
                                      <!-- Record Properties Header text -->
                                      <span
                                        :class="
                                          panelHeaderClass(
                                            hasAdvancedSearchableRecordProps
                                          )
                                        "
                                        >{{ filterHeaderRecord }}</span
                                      >
                                      <!-- Record Properties Sub Header text -->
                                      <core-chip-group
                                        v-show="
                                          hasAdvancedSearchableRecordProps
                                        "
                                        :items="
                                          filterAdvancedSearchableRecordProps
                                        "
                                        :column="true"
                                        :chip-small="true"
                                        :chip-close="true"
                                        @onSearchChipRemoved="
                                          event =>
                                            onSearchChipRemoved(event, false)
                                        "
                                      >
                                        <template #default="{ item: field }">
                                          {{
                                            recordPropCriteriaToString(field)
                                          }}
                                        </template>
                                      </core-chip-group>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <v-row dense>
                                        <!-- Record Id -->
                                        <v-col>
                                          <search-form-field
                                            :field="fieldRecordId"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row dense v-show="!isPublicAccess">
                                        <!-- Record Owner -->
                                        <v-col cols="12" md="6">
                                          <search-form-field
                                            :field="fieldRecordOwner"
                                          />
                                        </v-col>
                                        <!-- Record Creator -->
                                        <v-col cols="12" md="6">
                                          <search-form-field
                                            :field="fieldRecordCreator"
                                          />
                                        </v-col>
                                      </v-row>
                                      <!-- Record Creator Created On/From -->
                                      <search-form-field-range
                                        :field-from="fieldCreatedFrom"
                                        :field-to="fieldCreatedTo"
                                      />
                                      <!-- Record Creator Modified On -->
                                      <search-form-field-range
                                        :field-from="fieldModifiedFrom"
                                        :field-to="fieldModifiedTo"
                                      />
                                      <!-- Record Type -->
                                      <v-row dense>
                                        <v-col cols="12" md="6">
                                          <search-select-form-field
                                            :prepend-icon="
                                              selectedRecordTypeIcon
                                            "
                                            :items="searchableRecordTypes"
                                            :field="fieldRecordType"
                                            color="primary"
                                            item-text="name"
                                            item-value="id"
                                            label="Type"
                                            clearable
                                            dense
                                          >
                                          </search-select-form-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                          <search-select-form-field
                                            :items="searchableRecordStates"
                                            :field="fieldRecordState"
                                            color="primary"
                                            item-text="name"
                                            item-value="id"
                                            label="State"
                                            clearable
                                            dense
                                          >
                                          </search-select-form-field>
                                        </v-col>
                                      </v-row>
                                      <v-row
                                        dense
                                        v-if="visibleSearchFileProperties"
                                      >
                                        <!-- Record Extension -->
                                        <v-col cols="12" md="6">
                                          <search-form-field
                                            :disabled-wildcard="true"
                                            :field="fieldRecordExtension"
                                          />
                                        </v-col>
                                        <!-- Record Version -->
                                        <v-col cols="12" md="6">
                                          <search-form-field
                                            :field="fieldRecordVersion"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row
                                        dense
                                        v-if="visibleSearchFileProperties"
                                      >
                                        <!-- Record Pages -->
                                        <v-col cols="12" md="6">
                                          <search-form-field
                                            :field="fieldRecordPages"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                  <!-- More Fields -->
                                  <v-expansion-panel>
                                    <v-expansion-panel-header>
                                      <!-- More Fields Header text -->
                                      <span>
                                        <span
                                          :class="
                                            panelHeaderClass(
                                              hasSearchableFlyingFields
                                            )
                                          "
                                          class="mr-2"
                                          >{{ filterHeaderMoreFields }}</span
                                        >
                                      </span>

                                      <!-- More Fields Sub Header text -->
                                      <core-chip-group
                                        v-show="hasSearchableFlyingFields"
                                        :items="filterSearchableFlyingFields"
                                        :column="true"
                                        :chip-small="true"
                                        :chip-close="true"
                                        @onSearchChipRemoved="
                                          event =>
                                            onSearchChipRemoved(event, false)
                                        "
                                      >
                                        <template #default="props">
                                          {{
                                            fieldCriteriaToString(props.item)
                                          }}
                                        </template>
                                      </core-chip-group>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <!--
                                        Select More Fields Menu Dialog
                                      -->
                                      <v-row dense>
                                        <v-col>
                                          <base-toolbar>
                                            <more-fields-popover-menu
                                              v-model="visibleMoreFields"
                                            >
                                              <template #default>
                                                <more-fields
                                                  :fields="moreFields"
                                                  :error="addMoreFieldsError"
                                                  v-on:ok="onSelectMoreFieldsOk"
                                                  v-on:cancel="
                                                    onSelectMoreFieldsCancel
                                                  "
                                                >
                                                </more-fields>
                                              </template>
                                            </more-fields-popover-menu>
                                          </base-toolbar>
                                        </v-col>
                                      </v-row>

                                      <!--
                                        Display More Fields
                                      -->
                                      <v-row
                                        dense
                                        v-for="(field,
                                        index) in selectedFlyingFields"
                                        :key="index"
                                      >
                                        <v-col
                                          v-if="isFieldRangeSearchable(field)"
                                        >
                                          <search-form-field-range
                                            :field-from="field"
                                            :field-to="
                                              getRangeToField(
                                                selectedFlyingFields,
                                                field
                                              )
                                            "
                                            :removable="true"
                                            @trash="onRemoveFlyingField(field)"
                                            v-show="!field.rangeTo"
                                          />
                                        </v-col>
                                        <v-col v-else>
                                          <search-form-field
                                            :field="field"
                                            :removable="true"
                                            @trash="onRemoveFlyingField(field)"
                                          />
                                        </v-col>
                                      </v-row>
                                      <v-row dense> </v-row>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-container>
                        </v-form>
                      </v-card-text>
                    </template>

                    <template #actionsLeft>
                      <!-- Browse Search Template -->
                      <base-tooltip-button
                        :disabled="isPerformingSearch"
                        @click="onFilterDialogBrowse"
                      >
                        <!-- Tooltip for browsing -->
                        <template v-slot:toolTip>
                          {{ commandBrowseTooltip }}
                        </template>
                        <!-- Text for browsing -->
                        {{ commandBrowse }}
                      </base-tooltip-button>

                      <!-- Save Search Template -->
                      <base-tooltip-button
                        :disabled="isPerformingSearch"
                        @click="onFilterDialogSave"
                      >
                        <!-- Tooltip for saving -->
                        <template v-slot:toolTip>
                          {{ commandSaveTooltip }}
                        </template>
                        <!-- Text for saving -->
                        {{ commandSave }}
                      </base-tooltip-button>

                      <!-- Reset -->
                      <base-tooltip-button
                        :disabled="isPerformingSearch"
                        @click="onFilterDialogReset"
                      >
                        <!-- Tooltip for resetting -->
                        <template v-slot:toolTip>
                          {{ commandResetTooltip }}
                        </template>
                        <!-- Text for resetting -->
                        {{ commandSearchReset }}
                      </base-tooltip-button>
                    </template>

                    <!-- Filter Dialog actions -->
                    <template #actions>
                      <!-- Apply Search -->
                      <base-tooltip-button
                        :disabled="isSearching"
                        :loading="isApplyingSearch"
                        @click="onFilterDialogApply"
                      >
                        <!-- Tooltip for applying search -->
                        <template v-slot:toolTip>
                          {{ commandApplyLabelTooltip }}
                        </template>
                        <!-- Text for applying search -->
                        {{ commandApplyLabel }}
                      </base-tooltip-button>

                      <!-- Perform Search -->
                      <base-tooltip-button
                        :disabled="isApplyingSearch"
                        :loading="isSearching"
                        @click="onFilterDialogOk"
                      >
                        <!-- Tooltip for performing search -->
                        <template v-slot:toolTip>
                          {{ commandSearchLabelTooltip }}
                        </template>
                        <!-- Text for performing search -->
                        {{ commandSearchLabel }}
                      </base-tooltip-button>

                      <!-- Close Search Dialog -->
                      <base-tooltip-button
                        :disabled="isPerformingSearch"
                        @click="onFilterDialogCancel"
                      >
                        <!-- Tooltip for closing search dialog -->
                        <template v-slot:toolTip>
                          {{ commandCloseSearchDialogTooltip }}
                        </template>
                        <!-- Text for closing search dialog -->
                        {{ commandSearchCancelLabel }}
                      </base-tooltip-button>
                    </template>
                  </layout-dialog>
                </template>

                <!-- filter Tooltip -->
                <template #toolTip>
                  {{ filterTooltip }}
                </template>
              </search-filter-popover-menu>

              <!-- Show Record Details Dialog -->
              <record-details-dialog
                :record="editedItem"
                :category="editedItemCategory"
                :visible="visibleRecordDetailsDialog"
                @close="closeRecordDetailsDialog"
              >
              </record-details-dialog>

              <!-- Show Download File Dialog -->
              <download-file-dialog
                :record="downloadFileRecord"
                :visible="visibleDownloadFileDialog"
                :is-email="isEmail"
                @close="closeDownloadFileDialog"
              >
              </download-file-dialog>

              <!-- Show Print Options Dialog -->
              <print-file-dialog
                :record="downloadFileRecord"
                :visible="visiblePrintFileDialog"
                @close="closePrintFileDialog"
              >
              </print-file-dialog>

              <!-- Show Report Dialog -->
              <report-dialog
                :record-list="selectedItems"
                :search-headers="listHeaders"
                :visible="visibleReportDialog"
                :show-details="showRecordDetailsDialog"
                @close="closeReportDetailDialog"
              >
              </report-dialog>

              <!-- Document Editor Dialog -->
              <document-editor-dialog
                :id="editRecordId"
                :version="editRecordVersion"
                :visible="visibleDocumentEditorDialog"
                @update-record="onRecordChanged"
                @close="closeDocumentEditorDialog"
              ></document-editor-dialog>

              <!-- Document Editor Dialog -->
              <default-pdf-viewer-dialog
                :visible="visibleDefaultPdfViewerDialog"
                :record="viewedRecord"
                @onCheckout="recordCheckOut(true)"
                @close="closeDefaultPdfViewerDialog"
              ></default-pdf-viewer-dialog>
            </template>
          </layout-data-table-toolbar>

          <!-- Search Filter/Criteria -->
          <v-toolbar flat dense>
            <base-tooltip-icon
              v-if="searchCriteriaList.length"
              top
              :icon-name="iconResetFilter"
              :icon-color-class="filterColor"
              @click="resetSearchFilter(true)"
            >
              {{ resetFilterTooltip }}
            </base-tooltip-icon>

            <core-chip-group
              class="ml-6"
              :items="searchCriteriaList"
              :show-arrows="true"
              :chip-close="true"
              @onSearchChipRemoved="onSearchChipRemoved"
            ></core-chip-group>
          </v-toolbar>

          <!-- pagination of documents -->

          <v-pagination
            v-show="visiblePagination"
            v-model="modelPage"
            :circle="buttonRounded"
            :total-visible="maxVisiblePages"
            :color="buttonColorClass"
            :disabled="!enabledPagination"
            :length="pageLength"
            @input="onPaginationChanged"
          ></v-pagination>

          <!-- search toolbar -->
          <v-toolbar flat dense>
            <search-text-field
              v-model="fieldUniversalSearch.value"
              :label="labelSearchTextField"
              :disabled-universal-search-text="getDisabledSearchReason"
              @onSearchAction="applySearchFilter"
              :is-universal-search="enableUniversalSearch"
              :visible-search-button="true"
            ></search-text-field>
          </v-toolbar>
        </template>

        <!-- expanded-item -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <v-card flat :loading="visibleLoadChildrenProgress(item)">
                <v-list dense>
                  <template v-for="child in getChildren(item)">
                    <v-list-item :key="`child-${child.id}`">
                      <!-- icon -->
                      <v-list-item-icon>
                        <record-badge-icon :record="child"></record-badge-icon>
                      </v-list-item-icon>
                      <!-- content -->
                      <v-list-item-content>
                        <v-list-item-title>
                          <document-link
                            :record="child"
                            v-on:click="showDocumentEditorDialog"
                            :use-default-pdf-viewer="true"
                            @openDefaultPdfViewer="
                              openDefaultPdfViewerDialog(child)
                            "
                          ></document-link>
                        </v-list-item-title>
                      </v-list-item-content>
                      <!-- action -->
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-container>
          </td>
        </template>

        <!-- v-slot:item.data-table-expand -->
        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
          <base-tooltip-button
            :is-icon="true"
            v-if="showExpandIcon(item)"
            @click="expand(!isExpanded)"
          >
            <template v-slot:icon-name>
              {{ expandIcon(isExpanded) }}
            </template>
            <template v-slot:toolTip>
              {{ expandIconTooltip(item, isExpanded) }}
            </template>
          </base-tooltip-button>
        </template>

        <!-- Link to document viewer -->
        <template v-slot:item.name="{ item }">
          <document-link
            :record="item"
            :full-text-search-criteria="fullTextSearchCriteria"
            v-on:click="showDocumentEditorDialog"
            :use-default-pdf-viewer="true"
            @openDefaultPdfViewer="openDefaultPdfViewerDialog(item)"
          ></document-link>
        </template>

        <!-- record pages -->
        <template v-slot:item.pageCount="{ item }">
          <v-chip v-if="item.isSingleFile">
            {{ item.pageCount }}
          </v-chip>
        </template>

        <!-- record type -->
        <template v-slot:item.recordType="{ item }">
          <record-badge-icon :record="item"></record-badge-icon>
        </template>

        <!-- record state -->
        <template v-slot:item.state="{ item }">
          <record-state-chip :record="item"></record-state-chip>
        </template>

        <!-- Actions -->
        <template v-slot:item.actions="{ item }">
          <base-drop-down-menu-button top ref="refDropDownMenuActions">
            <template v-slot:iconName></template>
            <template v-slot:iconMenuDownName>{{
              iconDotsHorizontal
            }}</template>
            <template v-slot:commands>
              <!-- show Print File Dialog -->
              <template v-if="visibleCommandPrint(item)">
                <base-menu-item
                  @click="showPrintFileDialog(item)"
                  v-if="isAllowedPrintWithAnnotateOptions(item)"
                >
                  <template v-slot:iconName>
                    {{ commandPrintIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ tooltipPrintLabel(item) }}
                    </div>
                  </template>
                  {{ commandPrintLabel(item) }}
                </base-menu-item>
              </template>

              <!-- Show Download File Dialog -->
              <template v-if="visibleCommandDownload(item)">
                <base-menu-item @click="showDownloadFileDialog(item)">
                  <template v-slot:iconName>
                    {{ commandDownloadIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ tooltipDownloadLabel(item) }}
                    </div>
                  </template>
                  {{ commandDownloadLabel(item) }}
                </base-menu-item>
              </template>

              <!-- Show Email File Dialog - remarks: Under Development only -->
              <base-menu-item
                v-if="visibleCommandEmail(item)"
                @click="onEmailFile(item)"
              >
                <template v-slot:iconName>
                  {{ commandEmailIconName }}
                </template>
                <template v-slot:tooltipContent>
                  <div>
                    {{ tooltipEmailLabel(item) }}
                  </div>
                </template>
                {{ commandEmailLabel }}
              </base-menu-item>

              <!-- view record details -->
              <base-menu-item @click="showRecordDetailsDialog(item)">
                <template v-slot:icon>
                  <record-badge-icon
                    :record="item"
                    :info-only="true"
                  ></record-badge-icon>
                </template>
                <template v-slot:tooltipContent>
                  <div>
                    {{ tooltipViewRecordDetailsLabel(item) }}
                  </div>
                </template>
                {{ commandViewRecordDetailsLabel(item) }}
              </base-menu-item>

              <!-- Select File/Record in Folder Tree -->
              <base-menu-item
                v-show="visibleJumpToFile"
                :to="folderModuleRecordRouteLocation(item)"
                :disabled="!canNavigateToFolderModuleRecord(item)"
              >
                <template v-slot:iconName>
                  {{ commandSelectRecordIconName }}
                </template>
                <template v-slot:tooltipContent>
                  <div>
                    {{ tooltipSelectRecordLabel(item) }}
                  </div>
                </template>
                {{ commandSelectRecordLabel(item) }}
              </base-menu-item>

              <!-- <v-divider></v-divider> -->

              <!-- Add more menu items here -->
            </template>
          </base-drop-down-menu-button>
        </template>
      </v-data-table>
    </template>

    <!-- snackbar -->
    <template v-slot:snackbar>
      <snackbar
        v-model="snackbarVisible"
        @close="closeSnackbar"
        :color="snackbarColor"
        :top="snackbarTop"
        >{{ snackbarText }}</snackbar
      >
    </template>
  </base-table-layout>
  <!--      </v-expansion-panel-content>-->
  <!--    </v-expansion-panel>-->
  <!--  </v-expansion-panels>-->
</template>

<script>
import { dataTableCategoryDocumentsMixin } from "@/mixins/category/documents/dataTableCategoryDocumentsMixin";
import { annotationOptionMixin } from "@/mixins/shared/documents/annotationOptionMixin";

export default {
  name: "CategoryDocuments",
  mixins: [dataTableCategoryDocumentsMixin, annotationOptionMixin]
};
</script>
